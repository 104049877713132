var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item.isShow)?_c('div',{staticClass:"config-dialog"},[_c('div',{staticClass:"config-wrap"},[_c('div',{staticClass:"title"},[_vm._v("配置授权地址")]),_c('div',{staticClass:"ip-input"},[_c('span',{staticClass:"sub-title"},[_vm._v("服务器/网管地址")]),_c('el-input',{attrs:{"maxLength":"3"},on:{"input":function($event){return _vm.inputNum('ip1')}},model:{value:(_vm.ip1),callback:function ($$v) {_vm.ip1=$$v},expression:"ip1"}}),_c('span',{staticClass:"point"}),_c('el-input',{attrs:{"maxLength":"3"},on:{"input":function($event){return _vm.inputNum('ip2')}},model:{value:(_vm.ip2),callback:function ($$v) {_vm.ip2=$$v},expression:"ip2"}}),_c('span',{staticClass:"point"}),_c('el-input',{attrs:{"maxLength":"3"},on:{"input":function($event){return _vm.inputNum('ip3')}},model:{value:(_vm.ip3),callback:function ($$v) {_vm.ip3=$$v},expression:"ip3"}}),_c('span',{staticClass:"point"}),_c('el-input',{attrs:{"maxLength":"3"},on:{"input":function($event){return _vm.inputNum('ip4')}},model:{value:(_vm.ip4),callback:function ($$v) {_vm.ip4=$$v},expression:"ip4"}}),_c('div',{staticClass:"ls-btns",on:{"click":_vm.add}},[_vm._v("添加")])],1),_c('Table',{attrs:{"baseData":_vm.tableData,"tableHeader":_vm.tableHeader,"align":'center'}},[_c('el-table-column',{attrs:{"label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"ls-btns",style:({
              width: '80px',
              padding: 0,
              lineHeight: '35px',
              height: '35px',
              textAlign: 'center',
            }),on:{"click":function($event){return _vm.del(scope.$index)}}},[_vm._v(" 删除 ")])]}}],null,false,3553122389)})],1),_c('div',{staticClass:"btns"},[_c('div',{staticClass:"ls-btns-block",class:{ 'disabled': _vm.disabled },on:{"click":_vm.save}},[_vm._v("保存")]),_c('div',{staticClass:"ls-btns-block spin",on:{"click":_vm.close}},[_vm._v("关闭")])])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }