<template>
  <div v-if="item.isShow" class="ls-hint-dialog">
    <div class="hint-wrap" :class="item.type">
      <div class="ls-icons" :class="item.iconCss"></div>
      <div class="title">{{ item.title }}</div>
      <div class="content" v-html="item.content"></div>
      <div class="btns">
        <div v-if="item.btnFuncL" class="ls-btns-block" @click="item.btnFuncL">
          {{ item.btnTextL }}
        </div>
        <div v-if="item.btnFuncR" class="ls-btns-block spin" @click="item.btnFuncR">
          {{ item.btnTextR }}
        </div>
      </div>
      <div class="ls-icons icon-close" @click="onClose"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    onClose() {
      this.item.isShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.ls-hint-dialog {
  @extend %com-dialog-fixed;
  .hint-wrap {
    @extend %v-h-center;
    background-color: #fff;
    padding: 32px 80px 46px;
    text-align-last: center;
    .title {
      font-size: 18px;
      margin-top: 24px;
      margin-bottom: 20px;
      color: #3d3d3d;
      font-weight: bold;
    }
    .content {
      font-size: 14px;
      line-height: 20px;
      word-break: break-all;
      color: #3d3d3d;
      margin-bottom: 36px;
    }
    .btns {
      position: relative;
      font-size: 0;
      text-align: center;
      .ls-btns-block {
        display: inline-block;
        width: 150px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    &.error {
      .title {
        color: #e84335;
      }
    }
  }
  .icon-close {
    position: absolute;
    top: 9px;
    right: 12px;
    cursor: pointer;
  }
}
</style>