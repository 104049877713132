<template>
  <div v-if="item.isShow" class="config-dialog">
    <div class="config-wrap">
      <div class="title">配置授权地址</div>
      <div class="ip-input">
        <span class="sub-title">服务器/网管地址</span>
        <el-input
          v-model="ip1"
          @input="inputNum('ip1')"
          maxLength="3"
        ></el-input>
        <span class="point"></span>
        <el-input
          v-model="ip2"
          @input="inputNum('ip2')"
          maxLength="3"
        ></el-input>
        <span class="point"></span>
        <el-input
          v-model="ip3"
          @input="inputNum('ip3')"
          maxLength="3"
        ></el-input>
        <span class="point"></span>
        <el-input
          v-model="ip4"
          @input="inputNum('ip4')"
          maxLength="3"
        ></el-input>
        <div class="ls-btns" @click="add">添加</div>
      </div>

      <Table :baseData="tableData" :tableHeader="tableHeader" :align="'center'">
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <div
              @click="del(scope.$index)"
              class="ls-btns"
              :style="{
                width: '80px',
                padding: 0,
                lineHeight: '35px',
                height: '35px',
                textAlign: 'center',
              }"
            >
              删除
            </div>
          </template>
        </el-table-column>
      </Table>

      <div class="btns">
        <div class="ls-btns-block" :class="{ 'disabled': disabled }" @click="save">保存</div>
        <div class="ls-btns-block spin" @click="close">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ipWhiteList, updateIPwhite } from "@/api/interface/account.js";
export default {
  data() {
    return {
      ip1: undefined,
      ip2: undefined,
      ip3: undefined,
      ip4: undefined,
      tableData: [],
      tableHeader: this.$tableHeader.configs.ipconfig,
      disabled: true,
      originData: []
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal.isShow) {
          this.getIpList();
        }
      },
    },
  },
  methods: {
    inputNum(prop) {
      const pattern = /^[0-9]*$/;
      if (!pattern.test(this[prop])) {
        this[prop] = "";
      }
    },
    add() {
      if (this.ip1 && this.ip2 && this.ip3 && this.ip4) {
        const curIp = `${this.ip1}.${this.ip2}.${this.ip3}.${this.ip4}`;
        if (this.tableData.map((item) => item.ip).includes(curIp)) {
          this.$message.error("ip不能重复");
          return;
        }
        this.tableData = this.tableData.concat({
          no: this.tableData.length + 1,
          ip: curIp,
          addTime: new Date(),
        });
        this.ip1 = this.ip2 = this.ip3 = this.ip4 = undefined;
        this.isChangeData();
      } else {
        this.$message.error("请输入完整ip地址");
      }
    },
    del(index) {
      this.tableData = this.tableData
        .slice(0, index)
        .concat(this.tableData.slice(index + 1))
        .map((item, i) => {
          item.no = i + 1;
          return item;
        });
      this.isChangeData();
    },
    getIpList() {
      ipWhiteList().then((data) => {
        this.tableData = (data || []).map((item, i) => {
          item.no = i + 1;
          return item;
        });
        this.originData = [...(data || [])].map((item) => {
          return item.ip;
        });
      });
    },
    isChangeData() {
      this.disabled = true;
      if(this.tableData.length != this.originData.length) {
        this.disabled = false;
      }else {
        this.tableData.forEach(n => {
          if(!this.originData.includes(n.ip)) {
            this.disabled = false;
          }
        });
      }
    },
    save() {
      if(this.disabled) {
        return;
      }
      this.$confirm("确认保存？", "提示", {
        type: "warning",
        center: true,
      }).then((_) => {
        updateIPwhite(
          this.tableData.map((item) => {
            return item.ip;
          })
        ).then(() => {
          this.$message.success("保存成功");
          this.item.isShow = false;
        });
      });
    },
    close() {
      this.$confirm("未保存的数据将会丢失<br/>请确认是否执行关闭操作？", "提示", {
        type: "warning",
        center: true,
        dangerouslyUseHTMLString: true
      }).then((_) => {
        this.item.isShow = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/el/_table.scss";
.config-dialog {
  @extend %com-dialog-fixed;
  .config-wrap {
    @extend %v-h-center;
    padding: 16px 13px 36px;
    background-color: #fff;
    min-width: 705px;
    max-height: 1080px;
    overflow-y: auto;
  }
  .title {
    font-size: 14px;
    line-height: 30px;
    color: #3d3d3d;
    text-align: center;
    font-weight: 600;
    margin-bottom: 18px;
  }
  .ip-input {
    text-align: center;
    font-size: 0;
    margin-bottom: 35px;
    span,
    .ls-btns {
      display: inline-block;
      vertical-align: middle;
    }
    :deep() .el-input {
      display: inline-block;
      vertical-align: middle;
      width: 80px;
      text-align: center;
      margin: auto 10px;
    }
    .sub-title {
      font-size: 14px;
      line-height: 20px;
      color: #3d3d3d;
    }
    .ls-btns {
      margin-left: 5px;
    }
    .point {
      position: relative;
      background-color: #000000;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      top: 9px;
    }
  }
  .btns {
    margin-top: 46px;
    text-align: center;
    .ls-btns-block {
      display: inline-block;
      vertical-align: middle;
      &.spin {
        margin-left: 10px;
      }
      &.disabled {
        background-color: #999;
      }
    }
  }
}
</style>