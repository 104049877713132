<template>
  <section v-loading="loading" class="ls-container service-overview">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="main-section">
      <div class="grid-box">
        <!-- 数字资产服务 -->
        <div class="card-item">
          <div class="title-wrap">
            <span class="title">数字资产服务</span>
          </div>
          <div class="flex alignCenter spaceBetween service-item">
            <div>
              <div class="num">{{ publishNum }}</div>
              <div class="text">
                每一份资产的数字化发行都会在链上成功部署独立的智能合约
              </div>
            </div>
            <div class="flex alignCenter">
              <span class="status" :class="openCss">{{ openTxt }}</span>
              <el-popover placement="top-start" width="300" trigger="hover">
                <div>
                  <span class="ls-icons icon-dot gray"></span>
                  <span>接入并开通数字资产服务需要先完成账号的实名认证和企业认证；</span>
                  <br />
                  <br />
                  <span class="ls-icons icon-dot gray"></span>
                  <span>认证通过后，系统会自动为您创建接入账户并生成技术接入需要使用的证书和签名</span>
                </div>
                <div slot="reference" class="ls-icons icon-question2"></div>
              </el-popover>
            </div>
          </div>
        </div>

        <!-- 开发与对接指南 -->
        <div class="card-item">
          <div class="title-wrap">
            <span class="title">开发与对接指南</span>
          </div>
          <div class="flex alignCenter spaceBetween icons">
            <div
              v-for="icon in icons"
              :key="icon.icon"
              class="flex alignCenter justifyCenter column icon-item"
              @click="routeLink(icon)"
            >
              <div class="ls-icons" :class="icon.icon"></div>
              <span>{{ icon.label }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="num-box card-item info-card flex">
        <div class="flex quantity-box">
          <div class="quantity-item">
            <div class="label">今日燃料消耗</div>
            <div class="num">{{ formatNumber(assetData.gasUsed) }}</div>
          </div>
          <div v-if="chargeModel != 2" class="quantity-item">
            <div class="label">可用燃料剩余</div>
            <div class="flex">
              <div class="num">{{ formatNumber(assetData.gas) }}</div>
              <div @click="routeLink({ routeName: 'Recharge' })" class="ls-btns">
                充值
              </div>
            </div>
          </div>
          <div class="quantity-item">
            <div class="label">今日交易总数</div>
            <div class="num">{{ formatNumber(assetData.txCount) }}</div>
          </div>
        </div>

        <div class="permit-box">
          <div class="flex alignCenter">
            <div class="ls-icons icon-user-card"></div>
            <span class="label-text">服务接入通行证</span>
            <el-popover placement="top-start" width="300" trigger="hover">
              <div>
                <span class="ls-icons icon-dot gray"></span>
                <span>成功开通数字资产服务后，系统会为您发放技术接入通行证；</span>
                <br />
                <br />
                <span class="ls-icons icon-dot gray"></span>
                <span>在获得通行证之前，我们需要对您的服务器或者网关地址进行授权配置，请按照说明提供对应的信息；</span>
                <br />
                <br />
                <span class="ls-icons icon-dot gray"></span>
                <span>成功发放后您可以进行下载，并配合技术接入文档使用，即可快速完成服务对接；</span>
                <br />
                <br />
                <span class="ls-icons icon-dot gray"></span>
                <span>为了您的业务安全，下载完成使用后请妥善保管或者删除本地文档记录。</span>
              </div>
              <div slot="reference" class="ls-icons icon-question2"></div>
            </el-popover>
          </div>

          <div class="flex alignCenter status-box">
            <div class="text" :class="useCss">{{ useTxt }}</div>
            <div v-if="this.isUsed" class="cer-wrap">
              <div class="down-file" @click="addIpConfig">点击配置授权地址</div>
              <div class="down-file" @click="downloadCer">点击下载通行证文件</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 当日交易请求统计 -->
      <div class="echart-item card-item">
        <div class="title-wrap">
          <span class="title">当日交易请求统计</span>
          <span class="sub-title">00:00~23:59</span>
        </div>
        <div class="echart-box">
          <StackedLine :xData="xDataToday" :yData="yDataToday"></StackedLine>
        </div>
      </div>

      <!-- 近期交易数据统计 -->
      <div class="echart-item card-item">
        <div class="title-wrap">
          <span class="title">近期交易数据统计</span>
        </div>
        <div class="echart-box">
          <StackedLine
            :xData="xDataRecent"
            :yData="yDataRecent"
            :line-color="`#350182`"
          ></StackedLine>
        </div>
      </div>
    </div>

    <HintDialog :item="hintItem" />

    <ConfigIp :item="configItem" />
  </section>
</template>

<script>
/**
 * 服务概览
 */
import BreadCrump from "@/components/common/BreadCrump";
import StackedLine from "@/components/common/StackedLine";

import { downloadFile } from "@/utils/downLoad";
import { mapGetters, mapActions } from "vuex";
import { assetTotal, todayFlow, recentFlow } from "@/api/interface/assets.js";
import { ipWhiteList } from "@/api/interface/account.js";
import { formatNumber, lastDayRange } from "@/utils/common";
import { AXIS_HOURS } from "@/constants";
import dayjs from "dayjs";
import HintDialog from "@/components/common/HintDialog";
import ConfigIp from "@/components/configs/ConfigIp.vue";

export default {
  name: "ServiceOverview",
  components: {
    BreadCrump,
    StackedLine,
    HintDialog,
    ConfigIp,
  },
  data() {
    return {
      breadcrumbList: [{ menuName: "服务概览" }],
      icons: [
        {
          icon: "icon-overview-wd",
          label: "数字资产服务文档",
          link: "https://www.lingshu.net/solution/document",
        },
        {
          icon: "icon-overview-sdk",
          label: "SDK使用说明",
          routeName: "",
        },
        {
          icon: "icon-overview-zn",
          label: "测试调用指南",
          routeName: "",
        },
      ],
      assetData: {},
      xDataToday: [],
      yDataToday: [],
      xDataRecent: [],
      yDataRecent: [],
      loading: true,
      hintItem: { isShow: false },
      configItem: { isShow: false },
    };
  },
  computed: {
    ...mapGetters(["userInfo", "deviceInfo"]),
    enterpriseAuthStatus() {
      return this.userInfo.shop && this.userInfo.shop.auditStatus;
    },
    accessStatus() {
      return this.userInfo.shop && this.userInfo.shop.accessStatus;
    },
    isOpen() {
      return this.accessStatus == 1;
    },
    isUsed() {
      return this.assetData.verify && this.enterpriseAuthStatus == 2;
    },
    openCss() {
      return this.isOpen ? "success-color" : "fail-color";
    },
    openTxt() {
      return this.isOpen > 0 ? "已开通" : "未开通";
    },
    useCss() {
      return this.isUsed ? "success-color" : "fail-color";
    },
    useTxt() {
      return this.isUsed ? "已开放" : "未开放";
    },
    publishNum() {
      return this.isOpen
        ? `已发行 ${this.assetData.asserCount}`
        : "暂无数字资产发行记录";
    },
    chargeModel() {
      return this.userInfo.shop && this.userInfo.shop.chargeModel; 
    }
  },
  methods: {
    ...mapActions(['setUserInfo']),
    formatNumber,
    downloadCer() {
      ipWhiteList().then((data) => {
        if (data && data.length > 0) {
          downloadFile({
            filename: `工作台服务通行证`,
            url: `${this.deviceInfo.baseUrl}/v1/workbench/asset/permit/download`,
            fileType: ".zip",
            contentType: "application/force-download;charset=UTF-8",
            blobType: "application/zip"
          });
        } else {
          Object.assign(this.hintItem, {
            isShow: true,
            iconCss: "icon-hint-large",
            title: "提示",
            content:
              "您还未“配置服务授权地址”，请先添加服务器或者网关IP地址。<br /><br />未完成授权地址配置可能会导致您无法正常调用平台接入服务。",
            btnTextL: "去配置",
            btnFuncL: this.addIpConfig,
          });
        }
      });
    },
    getAssetTotal() {
      assetTotal()
        .then((data) => {
          this.assetData = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTodayFlow() {
      todayFlow().then((data) => {
        const tempArr = [];
        this.xDataToday = AXIS_HOURS;
        this.xDataToday.forEach((list, i) => {
          let txTotal = data ? 0 : "";
          data.forEach((item) => {
            if (item.txTime.split(" ")[1] == i) {
              txTotal = item.txTotal;
            }
          });
          tempArr.push(txTotal);
        });
        this.yDataToday = [...tempArr];
      });
    },
    getRecentFlow() {
      recentFlow().then((data) => {
        const tempArr = [];
        this.xDataRecent = [...lastDayRange(data.slice(-1).txTime, 45)];
        this.xDataRecent.forEach((list) => {
          let txTotal = data ? 0 : "";
          data.forEach((item) => {
            if (dayjs(item.txTime).format("MM-DD") == list) {
              txTotal = item.txTotal;
            }
          });
          tempArr.push(txTotal);
        });
        this.yDataRecent = [...tempArr];
      });
    },
    routeLink(item) {
      if (item.link) {
        window.open(item.link);
        return;
      }
      if (!item.routeName) {
        this.$message.info(this.$CommonMsg["building"]);
        return;
      }
      this.$router.push({ name: item.routeName });
    },
    addIpConfig() {
      this.hintItem.isShow = false;
      Object.assign(this.configItem, {
        isShow: true,
      });
    },
  },
  created() {
    this.setUserInfo({...this.userInfo});

    this.getAssetTotal();
    this.getTodayFlow();
    this.getRecentFlow();
  },
};
</script>

<style lang="scss" scoped>
.service-overview {
  .card-item {
    background: #ffffff;
    box-sizing: border-box;
  }

  .grid-box {
    margin-top: 15px;
    width: 100%;
    height: 200px;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    grid-template-rows: 200px 200px;
    grid-column-gap: 20px;
  }

  .num-box {
    margin-top: 20px;
    width: 100%;
    height: 200px;
  }

  .echart-item {
    margin-top: 20px;
    width: 100%;
    height: 620px;
    position: relative;

    &:last-child {
      margin-top: 35px;
    }

    .echart-box {
      position: absolute;
      left: 40px;
      right: 40px;
      top: 30px;
      bottom: 0px;
    }
  }

  .title-wrap {
    padding: 25px 34px;
    font-size: 0;

    .title,
    .sub-title {
      display: inline-block;
      vertical-align: middle;
    }

    .title {
      font-size: 14px;
      color: #0846ac;
      font-weight: bold;
    }

    .sub-title {
      font-size: 14px;
      color: #3d3d3d;
      margin-left: 10px;
    }
  }

  .icons {
    margin: 15px 90px;

    .icon-item {
      cursor: pointer;

      span {
        margin-top: 15px;
        font-size: 14px;
        color: #3d3d3d;
      }
    }
  }

  .service-item {
    margin: 15px 35px;
    font-size: 14px;
    color: #3d3d3d;

    .num {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 33px;
    }

    .status {
      margin-right: 15px;
    }
  }

  .info-card {
    padding: 56px 40px 0 40px;

    .quantity-box {
      color: #3d3d3d;

      .quantity-item {
        margin-right: 150px;

        .label {
          font-size: 18px;
          margin-bottom: 24px;
        }

        .num {
          font-size: 24px;
          font-weight: bold;
          margin-right: 45px;
          margin-left: 10px;
        }
      }
    }

    .permit-box {
      .label-text {
        font-size: 18px;
        color: #0846ac;
        margin-left: 15px;
        margin-right: 8px;
      }

      .status-box {
        align-items: flex-end;
        .text {
          font-size: 14px;
          margin-right: 100px;
          margin-left: 100px;
          margin-top: 42px;
        }

        .down-file {
          cursor: pointer;
          margin-left: 30px;
          font-size: 14px;
          color: #319ebc;
          text-decoration: underline;
          margin-top: 20px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}

:deep() .icon-dot {
  margin-right: 5px;
}
</style>
